import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const Translator = {
  lng: 'sv',
  fallbackLng: 'sv',
  backend: {
    loadPath: '/assets/locales/{{lng}}/translation.json',
  },
  react: {
    useSuspense: false,
  },
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init(Translator);

const useTranslator = () => useTranslation();

export { Translator, useTranslator };