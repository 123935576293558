import { useState } from "react";
import { useTranslator } from "../components/Translator";

import gravidHigh from "../media/photos/gravidHigh.jpg";
import nyfoddHigh from "../media/photos/nyfoddHigh.jpg";
import barnHigh from "../media/photos/barnHigh.jpg";
import portrattHigh from "../media/photos/portrattHigh.jpg";
import familjHigh from "../media/photos/familjHigh.jpg";

import Gravid from "../pages/galleries/GravidGalleryPackage";
import Nyfodd from "../pages/galleries/NyfoddGalleryPackage";
import Barn from "../pages/galleries/BarnGalleryPackage";
import Portratt from "../pages/galleries/PortrattGalleryPackage";
import Familj from "../pages/galleries/FamiljGalleryPackage";

const Portfolio = () => {
  const { t } = useTranslator();

  const [activePackage, setActivePackage] = useState(1);

  const packageButtons = [
    { id: 1, image: gravidHigh, textKey: "package1" },
    { id: 2, image: nyfoddHigh, textKey: "package2" },
    { id: 3, image: barnHigh, textKey: "package3" },
    { id: 4, image: portrattHigh, textKey: "package4" },
    { id: 5, image: familjHigh, textKey: "package5" },
  ];

  const showPackage = (x) => {
    setActivePackage(x);
  };

  return (
    <main id="portfolio" className="container">
        <div id="packageLinks" className="packageLinks">
            {packageButtons.map((button) => (
                <a href="#packageContainer" onClick={() => showPackage(button.id)} key={button.id} className={`package ${activePackage === button.id ? "active" : ""}`}>
                    <img src={button.image} alt={button.textKey} />
                    <p>{t(button.textKey)}</p>
                </a>
            ))}
        </div>

        <div id="packageContainer" className="showingContainer">
            <Gravid display={activePackage === 1 ? "package active" : "package inactive"} />
            <Nyfodd display={activePackage === 2 ? "package active" : "package inactive"} />
            <Barn display={activePackage === 3 ? "package active" : "package inactive"} />
            <Portratt display={activePackage === 4 ? "package active" : "package inactive"} />
            <Familj display={activePackage === 5 ? "package active" : "package inactive"} />
        </div>
    </main>
  );
};

export default Portfolio;