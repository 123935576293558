import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import Scroller from './components/Scroller.js';

//Google Analytics cookies are waiting for users consent
if(sessionStorage.getItem("cookieConsent") === "given"){
  ReactGA.initialize("UA-130316272-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log("GA enabled");
}else{
  window['ga-disable-UA-XXXXXX-Y'] = true;
  console.log("/index.js: Google Analytics waiting for cookie consent");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Scroller/>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);