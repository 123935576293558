import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//  COMPONENTS  //
import {useTranslator} from "../components/Translator"
//  COMPONENTS END  //

//  IMAGES  //
import logo from "../media/logo.png"
import logoBorder from "../media/logoBorder.svg"
import facebook from "../media/facebook.png"
import instagram from "../media/instagram.png"
import mail from "../media/mail.png"
import homeImage1 from "../media/home/homeImage.jpg"
import homeImage2 from "../media/home/homeImage2.jpg"
import homeImage3 from "../media/home/homeImage3.jpg"
import homeImage4 from "../media/home/homeImage4.jpg"
import homeImage5 from "../media/home/homeImage5.jpg"
import homeImage6 from "../media/home/homeImage6.jpg"
import homeImage7 from "../media/home/homeImage7.jpg"
import homeImage8 from "../media/home/homeImage8.jpg"
import homeImageBorder from "../media/home/homeImageBorder.svg"
import arrow from "../media/arrowOrange.svg"
import tripleks from "../media/photos/products/tripleksPromo.png"
import photoAlbum from "../media/photos/products/photoAlbumPromo.png"
import photoBook from "../media/photos/products/photoBookPromo.png"

// Photos section:
import gravidHigh from "../media/photos/gravidHigh.jpg";
import nyfoddHigh from "../media/photos/nyfoddHigh2.jpg";
import barnHigh from "../media/photos/barnHigh.jpg";
import portrattHigh from "../media/photos/portrattHigh.jpg";
import familjHigh from "../media/photos/familjHigh.jpg";
//  IMAGES END  //

//  Packages  //
import Gravid from "../pages/photos/Gravid"
import Nyfodd from "../pages/photos/Nyfodd"
import Barn from "../pages/photos/Barn"
import Portratt from "../pages/photos/Portratt"
import Familj from "../pages/photos/Familj"
//  PACKAGES END  //

const Home = () => {
    // Translator
    const { t } = useTranslator();

    // Home image states
    const [homeImage1classList, setHomeImage1classList] = useState("homeImage homeImageActive")
    const [homeImage2classList, setHomeImage2classList] = useState("homeImage")
    const [homeImage3classList, setHomeImage3classList] = useState("homeImage")
    const [homeImage4classList, setHomeImage4classList] = useState("homeImage")
    const [homeImage5classList, setHomeImage5classList] = useState("homeImage")
    const [homeImage6classList, setHomeImage6classList] = useState("homeImage")
    const [homeImage7classList, setHomeImage7classList] = useState("homeImage")
    const [homeImage8classList, setHomeImage8classList] = useState("homeImage")

    // Changing home image.
    const [countingHomeImage, setCountingHomeImage] = useState(1)

    // Changing function.
    function changeHomeImage(){
        if(countingHomeImage == 7){
            setHomeImage7classList("homeImage");
            setHomeImage8classList("homeImage homeImageActive");
            setCountingHomeImage(0);
            return
        }

        if(countingHomeImage == 6){
            setHomeImage6classList("homeImage");
            setHomeImage7classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 5){
            setHomeImage5classList("homeImage");
            setHomeImage6classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 4){
            setHomeImage4classList("homeImage");
            setHomeImage5classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 3){
            setHomeImage3classList("homeImage");
            setHomeImage4classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 2){
            setHomeImage2classList("homeImage");
            setHomeImage3classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 1){
            setHomeImage1classList("homeImage");
            setHomeImage2classList("homeImage homeImageActive");
        }

        if(countingHomeImage == 0){
            if(homeImage8classList == "homeImage homeImageActive"){
                setHomeImage8classList("homeImage");
                setHomeImage1classList("homeImage homeImageActive");
            } else{
                setCountingHomeImage(countingHomeImage + 1)
                return
            }
        }
        setCountingHomeImage(countingHomeImage + 1)
    }

    // Calling changing function.
    useEffect(() => {setTimeout(()=>changeHomeImage(), 5000)})

    // Packages visibility states
    const [gravidPackageState, setGravidPackageState] = useState("package active");
    const [nyfoddPackageState, setNyfoddPackageState] = useState("package inactive");
    const [barnPackageState, setBarnPackageState] = useState("package inactive");
    const [portrattPackageState, setPortrattPackageState] = useState("package inactive");
    const [familjPackageState, setFamiljPackageState] = useState("package inactive");

    // Product Banner state
    const [firstProductImageState, setFirstProductImageState] = useState("productImages left");
    const [secondProductImageState, setSecondProductImageState] = useState("productImages center");
    const [thirdProductImageState, setThirdProductImageState] = useState("productImages right");

    function showPackage (x){
        if (x === 1){
            setNyfoddPackageState("package inactive")
            setBarnPackageState("package inactive")
            setPortrattPackageState("package inactive")
            setFamiljPackageState("package inactive")
            setGravidPackageState("package active")
        }

        if (x === 2){
            setGravidPackageState("package inactive")
            setBarnPackageState("package inactive")
            setPortrattPackageState("package inactive")
            setFamiljPackageState("package inactive")
            setNyfoddPackageState("package active")
        }

        if (x === 3){
            setGravidPackageState("package inactive")
            setPortrattPackageState("package inactive")
            setFamiljPackageState("package inactive")
            setNyfoddPackageState("package inactive")
            setBarnPackageState("package active")
        }

        if (x === 4){
            setGravidPackageState("package inactive")
            setBarnPackageState("package inactive")
            setFamiljPackageState("package inactive")
            setNyfoddPackageState("package inactive")
            setPortrattPackageState("package active")
        }

        if (x === 5){
            setGravidPackageState("package inactive")
            setBarnPackageState("package inactive")
            setPortrattPackageState("package inactive")
            setNyfoddPackageState("package inactive")
            setFamiljPackageState("package active")
        }
    }

    function changeProductImage(x){
        if(x == 1){
            if(secondProductImageState == "productImages center"){
                setFirstProductImageState("productImages PBfromLeftToCenter")
                setTimeout(() => setFirstProductImageState("productImages center"),600)

                setSecondProductImageState("productImages PBfromCenterToRight")
                setTimeout(() => setSecondProductImageState("productImages right"),600)

                setThirdProductImageState("productImages PBhiddenRight")
            }

            if(thirdProductImageState == "productImages center"){
                setFirstProductImageState("productImages PBfromHiddenLeft")
                setTimeout(() => setFirstProductImageState("productImages left"),600)

                setSecondProductImageState("productImages PBfromLeftToCenter")
                setTimeout(() => setSecondProductImageState("productImages center"),600)

                setThirdProductImageState("productImages PBfromCenterToRight")
                setTimeout(() => setThirdProductImageState("productImages right"),600)
                
            }
        }

        if(x == 2){
            if(firstProductImageState == "productImages center"){
                setFirstProductImageState("productImages PBfromCenterToLeft")
                setTimeout(() => setFirstProductImageState("productImages left"),600)

                setSecondProductImageState("productImages PBfromRightToCenter")
                setTimeout(() => setSecondProductImageState("productImages center"),600)

                setThirdProductImageState("productImages PBfromHiddenRight")
                setTimeout(() => setThirdProductImageState("productImages right"),600)
            }

            if(secondProductImageState == "productImages center"){
                setFirstProductImageState("productImages PBhiddenLeft")
                setTimeout(() => setFirstProductImageState("productImages hidden"),600)

                setSecondProductImageState("productImages PBfromCenterToLeft")
                setTimeout(() => setSecondProductImageState("productImages left"),600)

                setThirdProductImageState("productImages PBfromRightToCenter")
                setTimeout(() => setThirdProductImageState("productImages center"),600)
            }
        }
    }

    const packageButtons = [
        { id: 1, image: gravidHigh, textKey: "package1" },
        { id: 2, image: nyfoddHigh, textKey: "package2" },
        { id: 3, image: barnHigh, textKey: "package3" },
        { id: 4, image: portrattHigh, textKey: "package4" },
        { id: 5, image: familjHigh, textKey: "package5" },
    ];

    return(
        <main id="home">
            <div id="startPage">
                <div id="homeCard">
                    <div id="homeLogo">
                        <img src={logo} alt="Fotoatelje logo"/>
                        <img src={logoBorder} alt="Logo border"/>
                    </div>

                    <h1>Fotograf</h1>

                    <h2>Marzena Raczycka</h2>

                    <p>{t("homeText")}</p>

                    <div id="homeSocials">
                        <div>
                            <a href="https://www.facebook.com/Fotoateljemarzenaraczycka" target="_blank" alt="Link to Facebook"><img src={facebook} alt="Facebook icon"/></a>
                            <a href="https://www.instagram.com/fotoatelje_mr/" target="_blank" alt="Link to Instagram"><img src={instagram} alt="Instagram icon"/></a>
                            <a href="mailto:fotoatelje.mr@hotmail.com" alt="Link to mail"><img src={mail} alt="Mail icon"/></a>
                        </div>
                    </div>

                    <Link id="homeButton" to="/products" alt="Link to portfolio">{t("showMoreButton")}</Link>
                </div>

                <div id="homeImage">
                    <img className={homeImage1classList} src={homeImage1} alt="Home photo"/>
                    <img className={homeImage2classList} src={homeImage2} alt="Home photo"/>
                    <img className={homeImage3classList} src={homeImage3} alt="Home photo"/>
                    <img className={homeImage4classList} src={homeImage4} alt="Home photo"/>
                    <img className={homeImage5classList} src={homeImage5} alt="Home photo"/>
                    <img className={homeImage6classList} src={homeImage6} alt="Home photo"/>
                    <img className={homeImage7classList} src={homeImage7} alt="Home photo"/>
                    <img className={homeImage8classList} src={homeImage8} alt="Home photo"/>
                    <img id="homeImageBorder" src={homeImageBorder} alt="Home Image Border"/>
                </div>
            </div>

            <div className="container">
                <div id="packageLinks" className="packageLinks">
                    {packageButtons.map((button) => (
                        <a href="#packageContainer" onClick={() => showPackage(button.id)} key={button.id}>
                            <img src={button.image} alt="" />
                            <p>{t(button.textKey)}</p>
                        </a>
                    ))}
                </div>

                <div id="packageContainer" className="showingContainer">
                    <Gravid display={gravidPackageState}/>
                    <Nyfodd display={nyfoddPackageState}/>
                    <Barn display={barnPackageState}/>
                    <Portratt display={portrattPackageState}/>
                    <Familj display={familjPackageState}/>
                </div>
            </div>

            <div id="productsBanner">
                <div className="productContainer">
                    <div className="arrow" onClick={() => changeProductImage(1)}>
                        <img src={arrow} alt="Back" />
                    </div>

                    <div className="images">
                        <div className={firstProductImageState}>
                            <div>
                                <img src={photoAlbum} alt="Product image placeholder" />
                                <Link to="/products" alt="Link to products">Photo Album</Link>
                            </div>
                        </div>

                        <div className={secondProductImageState}>
                            <div>
                                <img src={photoBook} alt="Product image placeholder" />
                                <Link to="/products" alt="Link to products">Photo Book</Link>
                            </div>
                        </div>

                        <div className={thirdProductImageState}>
                            <div>
                                <img src={tripleks} alt="Product image placeholder" />
                                <Link to="/products" alt="Link to products">Tripleks</Link>
                            </div>
                        </div>
                    </div>

                    <div className="arrow" onClick={() => changeProductImage(2)}>
                        <img src={arrow} alt="Next" />
                    </div>
                </div>

                <img className="border" src={homeImageBorder} alt="Border" />
                <img className="border" src={homeImageBorder} alt="Border" />
            </div>
        </main>
    );
}
export default Home;