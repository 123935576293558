import { useState } from "react";

//  COMPONENTS  //
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import ima1 from "../../media/photos/barn/barn1.jpg";
import ima2 from "../../media/photos/barn/barn2.jpg";
import ima3 from "../../media/photos/barn/barn3.jpg";
import ima4 from "../../media/photos/barn/barn4.jpg";
import ima5 from "../../media/photos/barn/barn5.jpg";
import ima6 from "../../media/photos/barn/barn6.jpg";

import ima7 from "../../media/photos/barn/barn7.jpg";
import ima8 from "../../media/photos/barn/barn8.jpg";
import ima9 from "../../media/photos/barn/barn9.jpg";
import ima10 from "../../media/photos/barn/barn10.jpg";
import ima11 from "../../media/photos/barn/barn11.jpg";
import ima12 from "../../media/photos/barn/barn12.jpg";
import ima13 from "../../media/photos/barn/barn13.jpg";
import ima14 from "../../media/photos/barn/barn14.jpg";
import ima15 from "../../media/photos/barn/barn15.jpg";
import ima16 from "../../media/photos/barn/barn16.jpg";
import ima17 from "../../media/photos/barn/barn17.jpg";
import ima18 from "../../media/photos/barn/barn18.jpg";
import ima19 from "../../media/photos/barn/barn19.jpg";
import ima20 from "../../media/photos/barn/barn20.jpg";
import ima21 from "../../media/photos/barn/barn21.jpg";
import ima22 from "../../media/photos/barn/barn22.jpg";
import ima23 from "../../media/photos/barn/barn23.jpg";
import ima24 from "../../media/photos/barn/barn24.jpg";
import ima25 from "../../media/photos/barn/barn25.jpg";
import ima26 from "../../media/photos/barn/barn26.jpg";
import ima27 from "../../media/photos/barn/barn27.jpg";
//  IMAGES END  //

const BarnGalleryPackage = ({display}) => {

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="barnGalleryPackage" className={display}>
            <div className="portfolioHeader">
                <h1>Portfolio</h1>
            </div>

            <div className="packageGallery">
                <img onClick={() => showModal(ima1)} src={ima1} className="wide" alt="Barn 1"/>
                <img onClick={() => showModal(ima3)} src={ima3} alt="Barn 2"/>
                <img onClick={() => showModal(ima2)} src={ima2} className="wide" alt="Barn 3"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Barn 4"/>
                <img onClick={() => showModal(ima4)} src={ima4} className="wide" alt="Barn 5"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Barn 6"/>
                <img onClick={() => showModal(ima7)} src={ima7} alt="Barn 7"/>
                <img onClick={() => showModal(ima8)} src={ima8} className="wide" alt="Barn 8"/>
                <img onClick={() => showModal(ima9)} src={ima9} alt="Barn 9"/>
                <img onClick={() => showModal(ima10)} src={ima10} className="wide" alt="Barn 10"/>
                <img onClick={() => showModal(ima11)} src={ima11} className="wide" alt="Barn 11"/>
                <img onClick={() => showModal(ima12)} src={ima12} alt="Barn 12"/>
                <img onClick={() => showModal(ima13)} src={ima13} alt="Barn 13"/>
                <img onClick={() => showModal(ima15)} src={ima15} className="wide" alt="Barn 14"/>
                <img onClick={() => showModal(ima16)} src={ima16} className="wide" alt="Barn 15"/>
                <img onClick={() => showModal(ima14)} src={ima14} alt="Barn 16"/>
                <img onClick={() => showModal(ima19)} src={ima19} alt="Barn 17"/>
                <img onClick={() => showModal(ima17)} src={ima17} className="wide" alt="Barn 18"/>
                <img onClick={() => showModal(ima21)} src={ima21} alt="Barn 19"/>
                <img onClick={() => showModal(ima18)} src={ima18} className="wide" alt="Barn 20"/>  
                <img onClick={() => showModal(ima20)} src={ima20} className="wide" alt="Barn 21"/>
                <img onClick={() => showModal(ima22)} src={ima22} alt="Barn 22"/>
                <img onClick={() => showModal(ima23)} src={ima23} alt="Barn 23"/>
                <img onClick={() => showModal(ima24)} src={ima24} className="wide" alt="Barn 24"/>
                <img onClick={() => showModal(ima26)} src={ima26} className="wide" alt="Barn 25"/>
                <img onClick={() => showModal(ima25)} src={ima25} alt="Barn 26"/>
                <img onClick={() => showModal(ima27)} src={ima27} className="wide" alt="Barn 27"/>
            </div>

            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default BarnGalleryPackage;