import { useState } from "react";

const CookiesBox = () =>{
  const [cookieBoxDisplay, setCookieBoxDisplay] = useState("block");

	function cookieConsent(){
		sessionStorage.setItem("cookieConsent", "given");
		setCookieBoxDisplay("none");
	}

	return(
	//Display cookie box if consent was not yet given, else render nothing
		<div>
			{(() => {
				if(sessionStorage.getItem("cookieConsent") === "given"){
					return null;
				}else{
					return(
						<div id="cookieBox" style={{display: cookieBoxDisplay}}>
							<div>
								<p>Vi använder cookies för att förbättra din upplevelse och 
								webbanalys (Google Analytics).</p>

								<button onClick={() => cookieConsent()}>Godkänn</button>
							</div>
						</div>
					)
				}
			})()}
		</div>
	)
}

export default CookiesBox;