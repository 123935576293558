import { useState } from "react";

//  COMPONENTS  //
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import ima1 from "../../media/photos/nyfodd/nyfodd1.jpg";
import ima2 from "../../media/photos/nyfodd/nyfodd2.jpg";
import ima3 from "../../media/photos/nyfodd/nyfodd3.jpg";
import ima4 from "../../media/photos/nyfodd/nyfodd4.jpg";
import ima5 from "../../media/photos/nyfodd/nyfodd5.jpg";
import ima6 from "../../media/photos/nyfodd/nyfodd6.jpg";
import ima7 from "../../media/photos/nyfodd/nyfodd7.jpg";
import ima8 from "../../media/photos/nyfodd/nyfodd8.jpg";
import ima9 from "../../media/photos/nyfodd/nyfodd9.jpg";
import ima10 from "../../media/photos/nyfodd/nyfodd10.jpg";
import ima11 from "../../media/photos/nyfodd/nyfodd11.jpg";
//  IMAGES END  //

const NyfoddGalleryPackage = ({display}) => {

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="nyfoddGalleryPackage" className={display}>
            <div className="portfolioHeader">
                <h1>Portfolio</h1>
            </div>

            <div className="packageGallery">
                <img onClick={() => showModal(ima11)} src={ima11} alt="Nyfodd 1"/>
                <img onClick={() => showModal(ima1)} src={ima1} className="wide" alt="Nyfodd 2"/>
                <img onClick={() => showModal(ima7)} src={ima7} className="wide" alt="Nyfodd 3"/>
                <img onClick={() => showModal(ima9)} src={ima9} alt="Nyfodd 4"/>
                <img onClick={() => showModal(ima10)} src={ima10} alt="Nyfodd 5"/>
                <img onClick={() => showModal(ima2)} src={ima2} className="wide" alt="Nyfodd 6"/>
                <img onClick={() => showModal(ima3)} src={ima3} className="wide" alt="Nyfodd 7"/>
                <img onClick={() => showModal(ima8)} src={ima8} alt="Nyfodd 8"/>
                <img onClick={() => showModal(ima4)} src={ima4} alt="Nyfodd 9"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Nyfodd 10"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Nyfodd 11"/>
            </div>

            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default NyfoddGalleryPackage;