const ProductModal = ({ display, action, source1, source2, source3 }) => {

    return(
        <div onClick={action} style={{ display: display }} id="productModal">
            <div id="modalContent">
                <img src={source1} alt="Product 1"></img>
                <img src={source2} alt="Product 2"></img>
                <img src={source3} alt="Product 3"></img>
            </div>
        </div>
    );
}

export default ProductModal