import { useState } from "react";

//  COMPONENTS  //
import {useTranslator} from "../components/Translator"
import ProductModal from "../components/ProductModal";
//  COMPONENTS END  //

/*  IMAGES  */
import photoAlbum1 from "../media/photos/products/photoAlbum1.jpg"
import photoAlbum2 from "../media/photos/products/photoAlbum2.jpg"
import photoAlbum3 from "../media/photos/products/photoAlbum3.jpg"

import passe1 from "../media/photos/products/passe1.jpg"
import passe2 from "../media/photos/products/passe2.jpg"
import passe3 from "../media/photos/products/passe3.jpg"

import photoBook1 from "../media/photos/products/photoBook1.jpg"
import photoBook2 from "../media/photos/products/photoBook2.jpg"
import photoBook3 from "../media/photos/products/photoBook3.jpg"

import harmonic1 from "../media/photos/products/harmonic1.jpg"
import harmonic2 from "../media/photos/products/harmonic2.jpg"
import harmonic3 from "../media/photos/products/harmonic3.jpg"

import tripleks1 from "../media/photos/products/tripleks1.jpg"
import tripleks2 from "../media/photos/products/tripleks2.jpg"
import tripleks3 from "../media/photos/products/tripleks3.jpg"
/*  IMAGES END  */

const Products = () => {
    // Translator
    const { t } = useTranslator();

    // Modal states
    const [modalDisplay, setModalDisplay] = useState("none");
    const [productPhoto1, setProductPhoto1] = useState();
    const [productPhoto2, setProductPhoto2] = useState();
    const [productPhoto3, setProductPhoto3] = useState();

    // Modal functions
    function displayAllImages(productId){
        setModalDisplay("block")

        if(productId == 1){
            setProductPhoto1(photoAlbum1)
            setProductPhoto2(photoAlbum2)
            setProductPhoto3(photoAlbum3)
        }

        if(productId == 2){
            setProductPhoto1(photoBook1)
            setProductPhoto2(photoBook2)
            setProductPhoto3(photoBook3)
        }

        if(productId == 3){
            setProductPhoto1(passe1)
            setProductPhoto2(passe2)
            setProductPhoto3(passe3)
        }

        if(productId == 4){
            setProductPhoto1(harmonic1)
            setProductPhoto2(harmonic2)
            setProductPhoto3(harmonic3)
        }

        if(productId == 5){
            setProductPhoto1(tripleks1)
            setProductPhoto2(tripleks2)
            setProductPhoto3(tripleks3)
        }
    }

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="products">
            <div id="productsHeader">
                <h1>{t("productsHeaderHeadline")}</h1>
                <p>{t("productsHeaderText")}</p>
            </div>

            <div id="productsContainer">
                <div className="product" onClick={()=>displayAllImages(1)}>
                    <div className="reccomended"><p>{t("reccomedation")}</p></div>
                    <img src={photoAlbum1} alt="Photo Album"/>
                    <div>
                        <h2>Photo Album</h2>
                        <p>{t("productPhotoAlbumPhotosAmount")}</p>
                        <p>20cm x 20cm</p>
                        <p>30cm x 30cm</p>
                        <p>15cm x 20cm</p>
                    </div>
                </div>

                <div className="product" onClick={()=>displayAllImages(2)}>
                    <div className="reccomended"><p>{t("reccomedation")}</p></div>
                    <img src={photoBook1} alt="Photo Book"/>
                    <div>
                        <h2>Photo Book</h2>
                        <p>{t("productPhotoBookPhotosAmount")}</p>
                        <p>30cm x 30cm</p>
                        <p>39cm x 20cm</p>
                    </div>
                </div>

                <div className="product" onClick={()=>displayAllImages(3)}>
                    <img src={passe1} alt=""/>
                    <div>
                        <h2>Pasportu</h2>
                        <p>20cm x 25cm</p>
                        <p>20cm x 30cm</p>
                        <p>25cm x 25cm</p>
                    </div>
                </div>

                <div className="product" onClick={()=>displayAllImages(4)}>
                    <img src={harmonic1} alt="Harmonic"/>
                    <div>
                        <h2>Harmonic</h2>
                        <p>{t("productHarmonicSidesAmount")}</p>
                        <p>7cm x 7cm</p>
                    </div>
                </div>

                <div className="product" onClick={()=>displayAllImages(5)}>
                    <div className="reccomended"><p>{t("reccomedation")}</p></div>
                    <img src={tripleks1} alt="Tripleks"/>
                    <div>
                        <h2>Tripleks</h2>
                        <p>{t("productTripleksPhotosAmount")}</p>
                        <p>15cm x 15cm</p>
                        <p>18cm x 18cm</p>
                        <p>20cm x 20cm</p>
                        <p>15cm x 23cm</p>
                    </div>
                </div>
            </div>

            <ProductModal action={hideModal} display={modalDisplay} source1={productPhoto1} source2={productPhoto2} source3={productPhoto3}/>
        </main>
    );
}
export default Products;