import { useState } from "react";

//  COMPONENTS  //
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import ima1 from "../../media/photos/familj/familj1.jpg";
import ima2 from "../../media/photos/familj/familj2.jpg";
import ima3 from "../../media/photos/familj/familj3.jpg";
import ima4 from "../../media/photos/familj/familj4.jpg";
import ima5 from "../../media/photos/familj/familj5.jpg";
import ima6 from "../../media/photos/familj/familj6.jpg";
import ima7 from "../../media/photos/familj/familj7.jpg";
import ima8 from "../../media/photos/familj/familj8.jpg";
import ima9 from "../../media/photos/familj/familj9.jpg";
import ima10 from "../../media/photos/familj/familj10.jpg";
import ima11 from "../../media/photos/familj/familj11.jpg";
import ima12 from "../../media/photos/familj/familj12.jpg";
//  IMAGES END  //

const FamiljGalleryPackage = ({display}) => {

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="familjGalleryPackage" className={display}>
            <div className="portfolioHeader">
                <h1>Portfolio</h1>
            </div>

            <div className="packageGallery">
                <img onClick={() => showModal(ima1)} src={ima1} alt="Familj 1"/>
                <img onClick={() => showModal(ima2)} src={ima2} className="wide" alt="Familj 2"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Familj 3"/>
                <img onClick={() => showModal(ima3)} src={ima3} className="wide" alt="Familj 4"/>
                <img onClick={() => showModal(ima4)} src={ima4} className="wide" alt="Familj 5"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Familj 6"/>
                <img onClick={() => showModal(ima7)} src={ima7} alt="Familj 7"/>
                <img onClick={() => showModal(ima8)} src={ima8} alt="Familj 8"/>
                <img onClick={() => showModal(ima9)} src={ima9} alt="Familj 9"/>
                <img onClick={() => showModal(ima10)} src={ima10} className="wide" alt="Familj 10"/>
                <img onClick={() => showModal(ima12)} src={ima12} alt="Familj 11"/>
                <img onClick={() => showModal(ima11)} src={ima11} className="wide" alt="Familj 12"/>
                
            </div>

            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default FamiljGalleryPackage;