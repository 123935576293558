import { useState } from "react";

//  COMPONENTS  //
import {useTranslator} from "../../components/Translator"
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import background from "../../media/photos/portratt/portrattHigh.jpg";
import ima1 from "../../media/photos/portratt/portratt1.jpg";
import ima2 from "../../media/photos/portratt/portratt2.jpg";
import ima3 from "../../media/photos/portratt/portratt3.jpg";
import ima4 from "../../media/photos/portratt/portratt4.jpg";
import ima5 from "../../media/photos/portratt/portratt5.jpg";
import ima6 from "../../media/photos/portratt/portratt6.jpg";
import arrow from "../../media/arrowOrange.svg"
import backgroundElement1 from "../../media/descriptionBackgroundElement1.svg"
import backgroundElement2 from "../../media/descriptionBackgroundElement2.svg"
//  IMAGES END  //

const Portratt = ({display}) => {
    // Translator
    const { t } = useTranslator();

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="portratt" className={display}>
            <div className="packageHeader">
                <img src={background}/>
                <h1>{t("package4")}</h1>
            </div>

            <div className="description">
                <div className="prices">
                    <div className="pricesInfo">
                        <p>{t("pricesInfo1")}</p>
                        <p>{t("pricesInfo2")}</p>
                        <p>{t("pricesInfo3")}</p>
                        <p>{t("pricesInfo4")}</p>
                        <p>{t("pricesInfo5")}</p>
                        <p>{t("pricesInfo6")}</p>
                        <p>{t("pricesInfo7")}</p>
                    </div>

                    <div className="cards">
                        <div className="flippingCard">
                            <div className="front face">
                                <h2>STANDARD</h2>
                                <p>{t("portrattStandardDigitalAmount")}</p>
                                <p>{t("portrattStandardPrintedAmount")}</p>
                            </div>

                            <div className="back face">
                                <p className="price">3000 kr</p>
                            </div>
                        </div>

                        <div className="flippingCard">
                            <div className="front face">
                                <h2>PREMIUM</h2>
                                <p>{t("standardDigitalAmount")}</p>
                                <p>{t("standardPrintedAmount")}</p>
                                <p>{t("premiumAlbumAddon")}</p>
                            </div>
                            
                            <div className="back face">
                                <p className="price">5000 kr</p>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="backgroundElement" src={backgroundElement1} alt="Background element 1"/>
                <img className="backgroundElement" src={backgroundElement2} alt="Background element 2"/>
            </div>

            <div className="packageGallery">
                <img onClick={() => showModal(ima1)} src={ima1} alt="Portrait 1"/>
                <img onClick={() => showModal(ima2)} src={ima2} alt="Portrait 2"/>
                <img onClick={() => showModal(ima3)} src={ima3} alt="Portrait 3"/>
                <img onClick={() => showModal(ima4)} src={ima4} alt="Portrait 4"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Portrait 5"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Portrait 6"/>
            </div>

            <div className="backToPackageLink">
                <a href="#packageLinks"  alt="Tillbaka till services link"><img src={arrow} alt="Arrow icon"/></a>
            </div>
            
            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default Portratt;