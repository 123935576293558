// Navigation
import { BrowserRouter as Router,  Routes, Route, NavLink  } from 'react-router-dom';

// Translator
import i18next from "i18next";
import {useTranslator} from "./components/Translator"

// States
import { useState } from "react";

//  STYLES //
import './App.css';
//  STYLES END  //

//  PAGES  //
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Products from "./pages/Products";
//  PAGES END  //

//  COMPONENTS  //
import Footer from "./components/Footer";
import CookiesBox from "./components/CookiesBox";
//  COMPONENTS END  //

//  IMAGES  //
import hamburger from "./media/hamburger.svg"
import facebook from "./media/facebook.png"
import instagram from "./media/instagram.png"
import mail from "./media/mail.png"
//  IMAGES END  //

export default function App(){
    // Translator
    const { t } = useTranslator();
	
	// Mobile navigation
	const [mobileNavigationState, setMobileNavigationState] = useState("inactive");

	function showMobileNavigation(x){
		if (x === 1){
			setMobileNavigationState("active")
		}

		if (x === 2){
			setMobileNavigationState("inactive")
		}
	}
	
	return(
		<div className="App">
			<header>
				<div id="navigations">
					<nav id="mobileMainNavigation">
						<button onClick={()=>showMobileNavigation(1)}>
							<img src={hamburger} alt="Hamburger icon"/>
						</button>

						<div id="hiddenMobileNav">
							<div className={mobileNavigationState}>
								<NavLink activeClassName="active" to="/" alt="Link to home page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkHome")}</NavLink>
								<NavLink activeClassName="active" to="/products" alt="Link to products page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkProducts")}</NavLink>
								<NavLink activeClassName="active" to="/portfolio" alt="Link to gallery page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkGallery")}</NavLink>
								<a href="#contact" alt="Link to contact section" onClick={()=>showMobileNavigation(2)}>Kontakt</a>
							</div>
						</div>
					</nav>

					<nav id="desktopMainNavigation">
						<NavLink activeClassName="active" to="/" alt="Link to home page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkHome")}</NavLink>
						<NavLink activeClassName="active" to="/products" alt="Link to peoducts page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkProducts")}</NavLink>
						<NavLink activeClassName="active" to="/portfolio" alt="Link to gallery page" onClick={()=>showMobileNavigation(2)}>{t("mainNavLinkGallery")}</NavLink>
						<a href="#contact" alt="Link to contact section" onClick={()=>showMobileNavigation(2)}>Kontakt</a>
					</nav>
				</div>

				<div id="headerFunctionalities">
					<div id="headerSocials">
					<div>
                            <a href="https://www.facebook.com/Fotoateljemarzenaraczycka" target="_blank" alt="Link to Facebook"><img src={facebook} alt="Facebook icon"/></a>
                            <a href="https://www.instagram.com/fotoatelje_mr/" target="_blank" alt="Link to Instagram"><img src={instagram} alt="Instagram icon"/></a>
                            <a href="mailto:fotoatelje.mr@hotmail.com" alt="Link to mail"><img src={mail} alt="Mail icon"/></a>
                        </div>
					</div>

					<div id="translator">
						<p onClick={() => i18next.changeLanguage("pl")}>PL</p>
						<p>/</p>
						<p onClick={() => i18next.changeLanguage("sv")}>SE</p>
					</div>
				</div>
			</header>

			<Routes>
				<Route exact path="/" element={<Home/>}></Route>
				<Route path="/products" element={<Products/>}></Route>
				<Route path="/portfolio" element={<Portfolio/>}></Route>
			</Routes>

			<Footer/>
			<CookiesBox/>
		</div>
	);
}