//  IMAGES  //
import contactImage from "../media/contactImage.jpg";
import localisationPin from "../media/localisationPin.svg";
import envelope from "../media/envelope.svg";
//  IMAGES END  //

const Footer = () =>{
    return(
        <footer>
            <div id="contact">
                <div>
                    <div id="contactCard">
                        <h1>KONTAKT</h1>

                        <h2>Marzena Raczycka Fotoatelje</h2>

                        <div>
                            <a href="https://www.facebook.com/Fotoateljemarzenaraczycka/" target="_blank" rel="noopener noreferrer">Facebook</a>
                            <a href="https://www.instagram.com/fotoatelje_mr/" target="_blank" rel="noopener noreferrer">Instagram</a>
                        </div>
                    </div>

                    
                    <div id="contactInfo">
                        <a href="https://www.google.com/maps/place/Fotograf+Marzena+Raczycka/@57.9567647,12.1205247,16z/data=!4m19!1m13!4m12!1m4!2m2!1d12.1372672!2d58.1238784!4e1!1m6!1m2!1s0x46455d3780fbaf97:0x7e1faecc38c7c87!2sfotoatelje+marzena+raczycka!2m2!1d12.1196619!2d57.9569576!3m4!1s0x46455d3780fbaf97:0x7e1faecc38c7c87!8m2!3d57.9569576!4d12.1196619" target="_blank" rel="noopener noreferrer">
                            <img src={localisationPin}></img>
                        </a>
                        <p>Älvängen 446-32</p>
                        <p>Göteborgsvägen 89</p>
                        <a href="mailto:fotoatelje.mr@hotmail.com">fotoatelje.mr@hotmail.com</a>
                        <a href="tel:0722150512">+46 722 150 512</a>
                    </div>
                </div>

                <div id="contactImage">
                    <img src={contactImage}></img>
                    <a href="mailto:fotoatelje.mr@hotmail.com"><img id="envelope" src={envelope} alt="Envelope icon"/></a>
                </div>
            </div>

            <div>
                <p>© Copyright {new Date().getFullYear()} Marzena Raczycka</p>
            </div>
        </footer>
    );
}

export default Footer;