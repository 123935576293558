import { useState } from "react";

//  COMPONENTS  //
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import ima1 from "../../media/photos/portratt/portratt1.jpg";
import ima2 from "../../media/photos/portratt/portratt2.jpg";
import ima3 from "../../media/photos/portratt/portratt3.jpg";
import ima4 from "../../media/photos/portratt/portratt4.jpg";
import ima5 from "../../media/photos/portratt/portratt5.jpg";
import ima6 from "../../media/photos/portratt/portratt6.jpg";
import ima7 from "../../media/photos/portratt/portratt7.jpg";
//  IMAGES END  //

const PortrattGalleryPackage = ({display}) => {

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="portrattGalleryPackage" className={display}>
            <div className="portfolioHeader">
                <h1>Portfolio</h1>
            </div>

            <div className="packageGallery">
                <img onClick={() => showModal(ima1)} src={ima1} alt="Portratt 1"/>
                <img onClick={() => showModal(ima2)} src={ima2} alt="Portratt 2"/>
                <img onClick={() => showModal(ima3)} src={ima3} alt="Portratt 3"/>
                <img onClick={() => showModal(ima4)} src={ima4} alt="Portratt 4"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Portratt 5"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Portratt 6"/>
                <img onClick={() => showModal(ima7)} src={ima7} alt="Portratt 7"/>
            </div>

            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default PortrattGalleryPackage;