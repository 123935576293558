import { useState } from "react";

//  COMPONENTS  //
import {useTranslator} from "../../components/Translator"
import Prices from "../../components/Prices";
import Modal from "../../components/Modal";
//  COMPONENTS END  //

//  IMAGES  //
import background from "../../media/photos/barn/barnHigh.jpg";
import ima1 from "../../media/photos/barn/barn1.jpg";
import ima2 from "../../media/photos/barn/barn2.jpg";
import ima3 from "../../media/photos/barn/barn3.jpg";
import ima4 from "../../media/photos/barn/barn4.jpg";
import ima5 from "../../media/photos/barn/barn5.jpg";
import ima6 from "../../media/photos/barn/barn6.jpg";
import arrow from "../../media/arrowOrange.svg"
//  IMAGES END  //

const Barn = ({display}) => {
    // Translator
    const { t } = useTranslator();

    // Gallery modal
    const [modalDisplay, setModalDisplay] = useState("none");
    const [activePhoto, setActivePhoto] = useState();

    // Modal functions
    const showModal = (source) => {
        if (window.innerWidth >= 800) {
            setModalDisplay('block');
            setActivePhoto(source);
        }
    };

    const hideModal = () => {
        setModalDisplay("none")
    }

    return(
        <main id="barn" className={display}>
            <div className="packageHeader">
                <img src={background}/>
                <h1>{t("package3")}</h1>
            </div>

            <Prices/>

            <div className="packageGallery">
                <img onClick={() => showModal(ima1)} className="wide" src={ima1} alt="Barn 1"/>
                <img onClick={() => showModal(ima3)} src={ima3} alt="Barn 3"/>
                <img onClick={() => showModal(ima5)} src={ima5} alt="Barn 5"/>
                <img onClick={() => showModal(ima2)} className="wide" src={ima2} alt="Barn 2"/>
                <img onClick={() => showModal(ima4)} className="wide" src={ima4} alt="Barn 4"/>
                <img onClick={() => showModal(ima6)} src={ima6} alt="Barn 6"/>
            </div>

            <div className="backToPackageLink">
                <a href="#packageLinks"  alt="Tillbaka till services link"><img src={arrow} alt="Arrow icon"/></a>
            </div>

            <Modal action={hideModal} display={modalDisplay} source={activePhoto}/>
        </main>
    );
}

export default Barn;