import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

//  IMAGES  //
import backgroundElement1 from "../media/descriptionBackgroundElement1.svg"
import backgroundElement2 from "../media/descriptionBackgroundElement2.svg"
//  IMAGES END  //

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: "sv",
    fallbackLng: "sv",
    backend: {loadPath: '/assets/locales/{{lng}}/translation.json'},
    react: {useSuspense: false}
  });

const Prices = () => {
// Translator
const {t} = useTranslation();

    return(
        <div className="description">
            <div className="prices">
                <div className="pricesInfo">
                    <p>{t("pricesInfo1")}</p>
                    <p>{t("pricesInfo2")}</p>
                    <p>{t("pricesInfo3")}</p>
                    <p>{t("pricesInfo4")}</p>
                    <p>{t("pricesInfo5")}</p>
                    <p>{t("pricesInfo6")}</p>
                    <p>{t("pricesInfo7")}</p>
                </div>

                <div className="cards">
                    <div className="flippingCard">
                        <div className="front face">
                            <h2>STANDARD</h2>
                            <p>{t("standardDigitalAmount")}</p>
                            <p>{t("standardPrintedAmount")}</p>
                            <p className="mobilePrice">3000 kr</p>
                        </div>

                        <div className="back face">
                            <p className="price">3000 kr</p>
                        </div>
                    </div>

                    <div className="flippingCard">
                        <div className="front face">
                            <h2>PREMIUM</h2>
                            <p>{t("premiumDigitalAmount")}</p>
                            <p>{t("premiumPrintedAmount")}</p>
                            <p>{t("premiumAlbumAddon")}</p>
                            <p className="mobilePrice">5000 kr</p>
                        </div>
                        
                        <div className="back face">
                            <p className="price">5000 kr</p>
                        </div>
                    </div>
                </div>
            </div>

            <img className="backgroundElement" src={backgroundElement1} alt="Background element 1"/>
            <img className="backgroundElement" src={backgroundElement2} alt="Background element 2"/>
        </div>
    );
}

export default Prices